import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOffer, signOffer } from "../services/api";
import { formatPrice } from "../lib/utils";
import { differenceInDays, differenceInHours, isBefore } from "date-fns";

interface LineItem {
  description: string;
  quantity: number;
  unitPrice: number;
  vatRate: number;
  subtotal: number;
  vatAmount: number;
  totalAmount: number;
}

interface Submission {
  id: string;
  eventVoornaam: string;
  eventNaam: string;
  eventEmail: string;
  formula: string;
  volwassenen: number;
  kinderen: number;
  vegetarisch: number;
  keuze: string;
  eventLocatieNaam: string;
  selectedBurgers: string[];
  desserts: Record<string, number>;
}

interface Offer {
  id: string;
  status: "DRAFT" | "PENDING" | "ACCEPTED" | "REJECTED";
  firstName: string;
  lastName: string;
  email: string;
  eventDate: string;
  eventStartTime: string;
  eventEndTime: string;
  companyName?: string;
  vatNumber?: string;
  billingStreet: string;
  billingNumber: string;
  billingZip: string;
  billingCity: string;
  subtotal: number;
  vatAmount: number;
  totalAmount: number;
  notes?: string;
  terms?: string;
  personalMessage?: string;
  lineItems: LineItem[];
  signedBy?: string;
  signedAt?: string;
  submission?: Submission;
  archived: boolean;
  dueDate: string;
}

export default function PublicOffer() {
  const { publicId } = useParams();
  const [offer, setOffer] = useState<Offer | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchOffer = async () => {
      try {
        if (!publicId) return;
        const data = await getOffer(publicId);
        setOffer(data);
      } catch (error) {
        setError("Failed to load offer");
        console.error("Error fetching offer:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOffer();
  }, [publicId]);

  const handleSign = async (action: "ACCEPTED" | "REJECTED") => {
    if (!publicId) return;
    if (!name.trim()) {
      alert("Please enter your name");
      return;
    }

    setIsSubmitting(true);
    try {
      const updatedOffer = await signOffer(publicId, name, action);
      setOffer(updatedOffer);
      window.location.reload();
    } catch (error) {
      setError("Failed to update offer");
      console.error("Error signing offer:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Group VAT amounts by rate
  const vatByRate = offer
    ? (offer.lineItems || []).reduce((acc, item) => {
        const rate = item.vatRate;
        if (!acc[rate]) {
          acc[rate] = 0;
        }
        acc[rate] += item.vatAmount;
        return acc;
      }, {} as { [key: number]: number })
    : {};

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-pulse text-2xl font-['Oswald'] text-gray-600">
          Loading...
        </div>
      </div>
    );
  }

  if (error || !offer || offer.archived) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-2xl font-['Oswald'] text-red-600">
          {error || "Offerte niet gevonden!"}
        </div>
      </div>
    );
  }

  if (offer.status === "DRAFT") {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-2xl font-['Oswald'] text-gray-600">
          De offerte is nog niet klaar! We zijn nog bezig met de
          voorbereidingen.
        </div>
      </div>
    );
  }

  const isEventWithin5Days = (eventDate: string) => {
    const now = new Date();
    const event = new Date(eventDate);
    const diffTime = event.getTime() - now.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 5 && diffDays >= 0;
  };

  const getRemainingTime = (dueDate: string) => {
    const now = new Date();
    const due = new Date(dueDate);
    const days = differenceInDays(due, now);
    const hours = differenceInHours(due, now) % 24;

    if (days > 0) {
      return `nog ${days} ${days === 1 ? "dag" : "dagen"} geldig`;
    } else if (hours > 0) {
      return `nog ${hours}u geldig`;
    } else {
      return "Vervallen";
    }
  };

  const isOfferExpired = offer
    ? isBefore(new Date(offer.dueDate), new Date())
    : false;

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="bg-white rounded-2xl shadow-[0_0_50px_0_rgba(0,0,0,0.1)] p-6 md:p-12">
          <h1 className="text-3xl font-['Oswald'] text-center mb-2">
            Burgerlijk On Tour: Offerte
          </h1>
          <div className="w-24 h-0.5 bg-brand-yellow mx-auto mb-6"></div>

          {/* Status Display */}
          <div
            className={`text-center text-xl mb-8 p-4 rounded-lg ${
              offer.status === "ACCEPTED"
                ? "bg-green-100 text-green-800"
                : offer.status === "REJECTED"
                ? "bg-red-100 text-red-800"
                : "bg-yellow-100 text-yellow-800"
            }`}
          >
            <div>
              Status:{" "}
              {offer.status === "ACCEPTED"
                ? "Geaccepteerd"
                : offer.status === "REJECTED"
                ? "Geweigerd"
                : "In afwachting"}
              {offer.signedBy && ` door ${offer.signedBy}`}
            </div>
            {offer.status === "PENDING" && (
              <div className="text-sm mt-2 font-semibold">
                {getRemainingTime(offer.dueDate)}
              </div>
            )}
          </div>

          {/* Banner for accepted offers within 5 days */}
          {offer.status === "ACCEPTED" &&
            isEventWithin5Days(offer.eventDate) && (
              <div
                className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-8"
                role="alert"
              >
                <p className="font-bold">Klopt dit nog?</p>
                <p>
                  Indien er nog aanpassingen zijn (aantal personen, keuze
                  burgers,….), laat het ons zo snel mogelijk weten!{" "}
                  <a href="mailto:ontour@burgerlijk.com">
                    ontour@burgerlijk.com
                  </a>
                </p>
              </div>
            )}

          {/* Client Information */}
          <div className="mb-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h2 className="text-2xl font-['Oswald'] mb-6">Klant</h2>
                <div className="space-y-4">
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Naam
                    </p>
                    <p className="text-lg">
                      {offer.firstName} {offer.lastName}
                    </p>
                  </div>
                  {offer.companyName && (
                    <div>
                      <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                        Bedrijfsnaam
                      </p>
                      <p className="text-lg">{offer.companyName}</p>
                    </div>
                  )}
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Email
                    </p>
                    <p className="text-lg">{offer.email}</p>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-2xl font-['Oswald'] mb-6">
                  Facturatie gegevens
                </h2>
                <div className="space-y-4">
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Adres
                    </p>
                    <p className="text-lg">
                      {offer.billingStreet} {offer.billingNumber}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Stad
                    </p>
                    <p className="text-lg">
                      {offer.billingZip} {offer.billingCity}
                    </p>
                  </div>
                  {offer.vatNumber && (
                    <div>
                      <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                        VAT Number
                      </p>
                      <p className="text-lg">{offer.vatNumber}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Event Details */}
          <div className="mb-12">
            <h2 className="text-2xl font-['Oswald'] mb-6">Evenement</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                  Datum
                </p>
                <p className="text-lg">
                  {new Date(offer.eventDate).toLocaleDateString()}
                </p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                  Tijd
                </p>
                <p className="text-lg">
                  {offer.eventStartTime} - {offer.eventEndTime}
                </p>
              </div>
            </div>
          </div>

          {/* Customer Request Details */}
          {offer.submission && (
            <div className="mb-12">
              <h2 className="text-2xl font-['Oswald'] mb-6">Klant Aanvraag</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Locatie
                    </p>
                    <p className="text-lg">
                      {offer.submission.eventLocatieNaam}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Formula
                    </p>
                    <p className="text-lg">{offer.submission.formula}</p>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Volwassenen
                    </p>
                    <p className="text-lg">{offer.submission.volwassenen}</p>
                  </div>
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Kinderen
                    </p>
                    <p className="text-lg">{offer.submission.kinderen}</p>
                  </div>
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Vegetarisch
                    </p>
                    <p className="text-lg">{offer.submission.vegetarisch}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                    Gekozen Burgers
                  </p>
                  <p className="text-lg">
                    {offer.submission.selectedBurgers.join(", ")}
                  </p>
                </div>
                {Object.entries(offer.submission.desserts).some(
                  ([, count]) => count > 0
                ) && (
                  <div>
                    <p className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-1">
                      Desserts
                    </p>
                    <div className="space-y-2">
                      {Object.entries(offer.submission.desserts)
                        .filter(([, count]) => count > 0)
                        .map(([name, count]) => (
                          <p key={name} className="text-lg">
                            {name}: {count}x
                          </p>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Personal Message */}
          {offer.personalMessage && (
            <div className="mb-12">
              <div className="bg-brand-yellow bg-opacity-10 border-l-4 border-brand-yellow p-6 rounded-lg">
                <p className="text-gray-800 whitespace-pre-line italic">
                  {offer.personalMessage}
                </p>
              </div>
            </div>
          )}

          {/* Line Items */}
          <div className="mb-12">
            <h2 className="text-2xl font-['Oswald'] mb-6">Items</h2>
            {/* Desktop view (hidden on mobile) */}
            <div className="hidden md:block overflow-x-auto">
              <div className="overflow-hidden rounded-lg border border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-6 py-4 text-left">
                        <span className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider">
                          Omschrijving
                        </span>
                      </th>
                      <th className="px-6 py-4 text-right">
                        <span className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider">
                          Hoeveelheid
                        </span>
                      </th>
                      <th className="px-6 py-4 text-right">
                        <span className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider">
                          Eenheidsprijs
                        </span>
                      </th>
                      <th className="px-6 py-4 text-right">
                        <span className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider">
                          BTW
                        </span>
                      </th>
                      <th className="px-6 py-4 text-right">
                        <span className="text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider">
                          Totaal
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {(offer.lineItems || []).map((item, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 text-gray-800">
                          {item.description}
                        </td>
                        <td className="px-6 py-4 text-right text-gray-800">
                          {item.quantity}
                        </td>
                        <td className="px-6 py-4 text-right text-gray-800">
                          {formatPrice(item.unitPrice)}
                        </td>
                        <td className="px-6 py-4 text-right text-gray-800">
                          {item.vatRate}%
                        </td>
                        <td className="px-6 py-4 text-right text-gray-800">
                          {formatPrice(item.totalAmount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="bg-gray-50">
                      <td colSpan={4} className="px-6 py-4 text-right">
                        <span className="font-['Oswald'] text-gray-600 uppercase tracking-wider">
                          Subtotaal
                        </span>
                      </td>
                      <td className="px-6 py-4 text-right font-['Oswald']">
                        {formatPrice(offer.subtotal)}
                      </td>
                    </tr>
                    {Object.entries(vatByRate).map(([rate, amount]) => (
                      <tr key={rate} className="bg-gray-50">
                        <td colSpan={4} className="px-6 py-4 text-right">
                          <span className="font-['Oswald'] text-gray-600 uppercase tracking-wider">
                            BTW ({rate}%)
                          </span>
                        </td>
                        <td className="px-6 py-4 text-right font-['Oswald']">
                          {formatPrice(amount)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-brand-yellow text-white">
                      <td colSpan={4} className="px-6 py-4 text-right">
                        <span className="font-['Oswald'] uppercase tracking-wider">
                          Total Bedrag
                        </span>
                      </td>
                      <td className="px-6 py-4 text-right font-['Oswald']">
                        {formatPrice(offer.totalAmount)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            {/* Mobile view (hidden on desktop) */}
            <div className="md:hidden space-y-4">
              {/* Line Items */}
              {(offer.lineItems || []).map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg border border-gray-200 p-4"
                >
                  <div className="font-['Oswald'] text-lg mb-2">
                    {item.description}
                  </div>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>
                      <span className="text-gray-600">Hoeveelheid:</span>
                      <span className="float-right">{item.quantity}</span>
                    </div>
                    <div>
                      <span className="text-gray-600">Prijs:</span>
                      <span className="float-right">
                        {formatPrice(item.unitPrice)}
                      </span>
                    </div>
                    <div>
                      <span className="text-gray-600">BTW:</span>
                      <span className="float-right">{item.vatRate}%</span>
                    </div>
                    <div>
                      <span className="text-gray-600">Totaal:</span>
                      <span className="float-right font-semibold">
                        {formatPrice(item.totalAmount)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              {/* Totals */}
              <div className="bg-gray-50 rounded-lg border border-gray-200 p-4 space-y-2">
                <div className="flex justify-between items-center">
                  <span className="font-['Oswald'] text-gray-600 uppercase tracking-wider">
                    Subtotaal
                  </span>
                  <span className="font-['Oswald']">
                    {formatPrice(offer.subtotal)}
                  </span>
                </div>
                {Object.entries(vatByRate).map(([rate, amount]) => (
                  <div key={rate} className="flex justify-between items-center">
                    <span className="font-['Oswald'] text-gray-600 uppercase tracking-wider">
                      BTW ({rate}%)
                    </span>
                    <span className="font-['Oswald']">
                      {formatPrice(amount)}
                    </span>
                  </div>
                ))}
                <div className="pt-2 border-t border-gray-200">
                  <div className="flex justify-between items-center font-['Oswald'] text-lg">
                    <span className="text-gray-900 uppercase tracking-wider">
                      Total Bedrag
                    </span>
                    <span className="text-brand-yellow">
                      {formatPrice(offer.totalAmount)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Terms and Notes */}
          {(offer.terms || offer.notes) && (
            <div className="mb-12 space-y-8">
              {offer.terms && (
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h2 className="text-2xl font-['Oswald'] mb-4">Terms</h2>
                  <p className="text-gray-600 whitespace-pre-line">
                    {offer.terms}
                  </p>
                </div>
              )}
              {offer.notes && (
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h2 className="text-2xl font-['Oswald'] mb-4">
                    Additional Notes
                  </h2>
                  <p className="text-gray-600 whitespace-pre-line">
                    {offer.notes}
                  </p>
                </div>
              )}
            </div>
          )}

          {/* Sign Form or Expired Message */}
          {offer.status === "PENDING" && (
            <div className="border-t pt-12">
              <h2 className="text-2xl font-['Oswald'] text-center mb-2">
                Offerte tekenen
              </h2>
              <div className="w-24 h-0.5 bg-brand-yellow mx-auto mb-8"></div>
              {isOfferExpired ? (
                <div className="text-center text-red-600 font-semibold">
                  Due date has passed, please ask for a new offer
                </div>
              ) : (
                <div className="max-w-md mx-auto space-y-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-['Oswald'] text-gray-600 uppercase tracking-wider mb-2"
                    >
                      Uw naam
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-brand-yellow focus:border-brand-yellow"
                      placeholder="Gelieve uw volledige naam in te vullen"
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <button
                      onClick={() => handleSign("ACCEPTED")}
                      disabled={isSubmitting}
                      className="bg-brand-yellow text-white px-6 py-3 rounded-lg font-['Oswald'] hover:bg-opacity-90 transition-all duration-200 disabled:opacity-50"
                    >
                      ACCEPTEREN
                    </button>
                    <button
                      onClick={() => handleSign("REJECTED")}
                      disabled={isSubmitting}
                      className="bg-gray-600 text-white px-6 py-3 rounded-lg font-['Oswald'] hover:bg-opacity-90 transition-all duration-200 disabled:opacity-50"
                    >
                      WEIGEREN
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
