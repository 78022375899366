import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import SummaryPopover from "../../components/SummaryPopover";
import { FormulaSection } from "./components/FormulaSection";
import { EventDetailsSection } from "./components/EventDetailsSection";
import { AddressSection } from "./components/AddressSection";
import { ContactSection } from "./components/ContactSection";
import { BurgerSection } from "./components/BurgerSection";
import { DessertSection } from "./components/DessertSection";
import { useOfferteForm } from "./hooks/useOfferteForm";
function Offerte() {
  const {
    formData,
    showSummary,
    isSubmitting,
    submitError,
    validationErrors,
    handleSubmit,
    handleConfirm,
    handleBurgerSelection,
    handleSameAsEventChange,
    handleEventAddressChange,
    updateFormData,
    updateDessertCount,
    setShowSummary,
  } = useOfferteForm();

  const hasValidationErrors = Object.keys(validationErrors).length > 0;

  return (
    <div className="min-h-screen bg-white">
      <Navigation />

      {/* Hero Section */}
      <section className="hero-section">
        <div className="z-10 transform -translate-y-8">
          <h1 className="text-[100px] font-bold tracking-wide mb-4">
            OFFERTE AANVRAGEN
          </h1>
          <div className="w-24 h-0.5 bg-brand-yellow mx-auto mt-6"></div>
        </div>
      </section>

      {/* Form Section */}
      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-2xl p-12 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
            {hasValidationErrors && (
              <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
                <p className="text-red-600 font-medium">
                  Vul alle verplichte velden in om door te gaan.
                </p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-8">
              <FormulaSection
                formData={formData}
                updateFormData={updateFormData}
                validationErrors={validationErrors}
              />

              <EventDetailsSection
                formData={formData}
                updateFormData={updateFormData}
                validationErrors={validationErrors}
              />

              <AddressSection
                title="ADRES VAN EVENEMENT"
                type="event"
                formData={formData}
                onAddressChange={handleEventAddressChange}
                validationErrors={validationErrors}
              />

              <ContactSection
                formData={formData}
                onAddressChange={handleEventAddressChange}
                validationErrors={validationErrors}
              />

              <AddressSection
                title="FACTUUR GEGEVENS"
                type="factuur"
                formData={formData}
                disabled={formData.sameAsEvent}
                onAddressChange={updateFormData}
                extraContent={
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="sameAsEvent"
                      checked={formData.sameAsEvent}
                      onChange={handleSameAsEventChange}
                      className="h-4 w-4 text-brand-yellow border-gray-300 rounded focus:ring-brand-yellow"
                    />
                    <label
                      htmlFor="sameAsEvent"
                      className="text-sm text-gray-600"
                    >
                      Zelfde als evenement
                    </label>
                  </div>
                }
              />

              <BurgerSection
                formData={formData}
                onBurgerSelection={handleBurgerSelection}
                validationErrors={validationErrors}
              />

              <DessertSection
                formData={formData}
                onDessertCountChange={updateDessertCount}
              />

              {/* Additional Comments */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Opmerkingen
                </label>
                <textarea
                  rows={4}
                  value={formData.opmerkingen}
                  onChange={(e) =>
                    updateFormData("opmerkingen", e.target.value)
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow"
                  placeholder="Heeft u nog specifieke wensen of opmerkingen?"
                />
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-3.5 px-4 border border-transparent rounded-lg text-sm font-medium text-white bg-brand-yellow hover:bg-brand-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow transition-colors font-['Oswald']"
                >
                  OFFERTE AANVRAGEN
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      {showSummary && (
        <SummaryPopover
          formData={formData}
          onClose={() => setShowSummary(false)}
          onConfirm={handleConfirm}
          isSubmitting={isSubmitting}
          error={submitError}
        />
      )}

      <Footer />
    </div>
  );
}

export default Offerte;
