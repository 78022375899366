import { FormulaOption, DessertOption } from './types';

export const FORMULA_OPTIONS: FormulaOption[] = [
  {
    id: "optie1",
    name: "Optie 1: Per geconsumeerde burger",
    description:
      "🍔 Keuze uit 4 verschillende burgers. Tarief per burger.",
  },
  {
    id: "optie2",
    name: "Optie 2: Mini Burger Festival",
    description:
      "🎉 Keuze uit 4 verschillende burgers. Deze brengen we in een mini versie. Wij blijven burgers maken en leggen de 4 soorten op plateaus. Jullie zorgen zelf voor de bediening aan tafel. Wij stoppen met bakken vanaf wanneer jullie bij ons komen melden dat iedereen voldoende heeft. Minimale afname 2 burgers per volwassene / 1 per kind, hierna wordt per burger aangerekend."
  },
];

export const DESSERT_OPTIONS: DessertOption[] = [
  { id: "chocoladeMousse", name: "Chocolade mousse", price: 4.00 },
  { id: "tiramisuClassic", name: "Tiramisu classic", price: 4.00 },
  { id: "tiramisuSpeculoos", name: "Tiramisu speculoos", price: 4.00 },
  { id: "chocoladeBrownie", name: "Chocolade Brownie", price: 4.00 },
  { id: "lemonCheeseCake", name: "Lemon Cheese Cake", price: 4.00 },
];

export const INITIAL_FORM_DATA = {
  formula: "",
  volwassenen: 0,
  kinderen: 0,
  vegetarisch: 0,
  keuze: "foodburgers",
  datum: "",
  startTijd: "",
  eindTijd: "",
  eventLocatieNaam: "",
  eventNaam: "",
  eventVoornaam: "",
  eventStraat: "",
  eventHuisnummer: "",
  eventPostcode: "",
  eventGemeente: "",
  eventEmail: "",
  eventPhoneNumber: "",
  factuurStraat: "",
  factuurHuisnummer: "",
  factuurPostcode: "",
  factuurGemeente: "",
  btwnummer: "",
  bedrijfsnaam: "",
  selectedBurgers: [],
  desserts: {
    chocoladeMousse: 0,
    tiramisuClassic: 0,
    tiramisuSpeculoos: 0,
    chocoladeBrownie: 0,
    lemonCheeseCake: 0,
  },
  opmerkingen: "",
  sameAsEvent: false,
};
