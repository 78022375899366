// Google Analytics
export const GA_TRACKING_ID = 'G-97KM67YNJW';

declare global {
  interface Window {
    dataLayer: unknown[];
    gtag: (...args: unknown[]) => void;
  }
}

export const initGA = (): void => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag(...args: unknown[]): void {
    window.dataLayer.push(args);
  }
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID);

  // Add gtag to window object
  window.gtag = gtag;
};

export const logPageView = (): void => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname,
    });
  }
};

export const logEvent = (category: string, action: string): void => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', action, {
      event_category: category,
    });
  }
};
