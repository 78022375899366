import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App.tsx'
import Offerte from './pages/Offerte.tsx'
import WerkingInfo from './pages/WerkingInfo.tsx'
import PublicOffer from './pages/PublicOffer.tsx'
import PrivacyPolicy from './pages/PrivacyPolicy.tsx'
import './index.css'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/offerte",
    element: <Offerte />,
  },
  {
    path: "/werking-info",
    element: <WerkingInfo />,
  },
  {
    path: "/offers/:publicId",
    element: <PublicOffer />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  }
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
