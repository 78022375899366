import React, { useState, useEffect } from 'react';

interface CookieConsentProps {
  onAccept: () => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ onAccept }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    } else {
      onAccept();
    }
  }, [onAccept]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
    onAccept();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between items-center">
      <p>
        Wij gebruiken cookies om uw ervaring te verbeteren. Door onze site te blijven gebruiken, gaat u akkoord met ons{' '}
        <a href="/privacy-policy" className="underline">
          privacybeleid
        </a>
        .
      </p>
      <button
        onClick={handleAccept}
        className="bg-white text-gray-800 px-4 py-2 rounded hover:bg-gray-200 transition-colors"
      >
        Accepteren
      </button>
    </div>
  );
};

export default CookieConsent;
