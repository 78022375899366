import axios from 'axios'
import { BurgerOption } from '../pages/Offerte/types';

declare global {
  interface Window {
    RUNTIME_CONFIG?: {
      API_URL: string;
      CLIENT_URL: string;
      BACKOFFICE_URL: string;
    }
  }
}

// In development, use Vite env vars
// In production, use runtime config
const getBaseUrl = () => {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_API_URL
  }
  return window.RUNTIME_CONFIG?.API_URL
}

const api = axios.create({
  baseURL: `${getBaseUrl()}/public`
})

export const getOffer = async (publicId: string) => {
  try {
    const response = await api.get(`/offers/${publicId}`)
    return response.data.data
  } catch (error) {
    console.error('Failed to fetch offer:', error)
    throw error
  }
}

export const signOffer = async (publicId: string, name: string, action: 'ACCEPTED' | 'REJECTED') => {
  try {
    const response = await api.post(`/offers/${publicId}/sign`, {
      name,
      action
    })
    return response.data.data
  } catch (error) {
    console.error('Failed to sign offer:', error)
    throw error
  }
}

// Function to fetch burger options from the server
export const getBurgerOptions = async (): Promise<BurgerOption[]> => {
  try {
    const response = await api.get(`/burger-options`)
    return response.data.data as BurgerOption[]
  } catch (error) {
    console.error('Error fetching burger options:', error);
    throw error;
  }
};


export default api
