import React from 'react';
import { FormData } from '../types';

interface EventDetailsSectionProps {
  formData: FormData;
  updateFormData: (field: keyof FormData, value: string | number | boolean | string[] | {
    chocoladeMousse: number;
    tiramisuClassic: number;
    tiramisuSpeculoos: number;
    chocoladeBrownie: number;
    lemonCheeseCake: number;
  }) => void;
  validationErrors?: { [key: string]: string };
}

export const EventDetailsSection: React.FC<EventDetailsSectionProps> = ({
  formData,
  updateFormData,
  validationErrors = {}
}) => {
  const handleNumberInput = (field: keyof FormData, value: string) => {
    // Allow 0 and positive integers
    const numberValue = value === '' ? 0 : Math.max(0, parseInt(value, 10));
    updateFormData(field, numberValue);
  };

  // Generate time options with 30-minute intervals
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const hourStr = hour.toString().padStart(2, "0");
      options.push(`${hourStr}:00`);
      options.push(`${hourStr}:30`);
    }
    return options;
  };

  const timeOptions = generateTimeOptions();

  return (
    <div className="space-y-6">
      <div className="pt-4">
        <h3 className="text-xl font-bold text-gray-900 font-['Oswald'] border-b border-gray-200 pb-4">
          AANTAL PERSONEN
        </h3>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Aantal Volwassenen <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="volwassenen"
            value={formData.volwassenen}
            onChange={(e) => handleNumberInput("volwassenen", e.target.value)}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors.volwassenen ? 'border-red-500' : 'border-gray-300'
            }`}
            min="0"
          />
          {validationErrors.volwassenen && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.volwassenen}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Aantal Kinderen <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="kinderen"
            value={formData.kinderen}
            onChange={(e) => handleNumberInput("kinderen", e.target.value)}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors.kinderen ? 'border-red-500' : 'border-gray-300'
            }`}
            min="0"
          />
          {validationErrors.kinderen && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.kinderen}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Aantal Vegetarisch <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="vegetarisch"
            value={formData.vegetarisch}
            onChange={(e) => handleNumberInput("vegetarisch", e.target.value)}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors.vegetarisch ? 'border-red-500' : 'border-gray-300'
            }`}
            min="0"
          />
          {validationErrors.vegetarisch && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.vegetarisch}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Datum van het event <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            name="datum"
            value={formData.datum}
            onChange={(e) => updateFormData("datum", e.target.value)}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors.datum ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {validationErrors.datum && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.datum}</p>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Startuur <span className="text-red-500">*</span>
            </label>
            <select
              name="startTijd"
              value={formData.startTijd}
              onChange={(e) => updateFormData("startTijd", e.target.value)}
              className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
                validationErrors.startTijd ? 'border-red-500' : 'border-gray-300'
              }`}
            >
              <option value="">Selecteer tijd</option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
            {validationErrors.startTijd && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.startTijd}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Einduur <span className="text-red-500">*</span>
            </label>
            <select
              name="eindTijd"
              value={formData.eindTijd}
              onChange={(e) => updateFormData("eindTijd", e.target.value)}
              className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
                validationErrors.eindTijd ? 'border-red-500' : 'border-gray-300'
              }`}
            >
              <option value="">Selecteer tijd</option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
            {validationErrors.eindTijd && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.eindTijd}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
