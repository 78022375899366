import React, { useState, useEffect } from "react";
import { FormData, BurgerOption } from "../types";
import { getBurgerOptions } from "../../../services/api";

interface BurgerSectionProps {
  formData: FormData;
  onBurgerSelection: (burgerId: string) => void;
  validationErrors?: { [key: string]: string };
}

export const BurgerSection: React.FC<BurgerSectionProps> = ({
  formData,
  onBurgerSelection,
  validationErrors = {},
}) => {
  const [burgerOptions, setBurgerOptions] = useState<BurgerOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadBurgerOptions = async () => {
      try {
        const options = await getBurgerOptions();
        setBurgerOptions(options);
        setLoading(false);
      } catch (err) {
        setError(
          `Failed to load burger options. Please try again later. Error: ${
            err instanceof Error ? err.message : String(err)
          }`
        );
        setLoading(false);
      }
    };

    loadBurgerOptions();
  }, []);

  if (loading) {
    return <div>Loading burger options...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-gray-900 font-['Oswald']">
          BURGERS <span className="text-red-500">*</span>
        </h3>
        <span className="text-sm text-gray-500">Kies 1 tot 4 burgers</span>
      </div>
      <div
        className={`space-y-4 ${
          validationErrors.selectedBurgers
            ? "p-4 border border-red-200 rounded-lg bg-red-50"
            : ""
        }`}
      >
        {burgerOptions.map((burger) => (
          <div key={burger.id} className="flex items-start space-x-3">
            <input
              type="checkbox"
              id={burger.id}
              name="selectedBurgers"
              checked={formData.selectedBurgers.includes(burger.id)}
              onChange={() => onBurgerSelection(burger.id)}
              disabled={
                !formData.selectedBurgers.includes(burger.id) &&
                formData.selectedBurgers.length >= 4
              }
              className={`mt-1 h-4 w-4 text-brand-yellow border-gray-300 rounded focus:ring-brand-yellow ${
                validationErrors.selectedBurgers ? "border-red-500" : ""
              }`}
            />
            <label htmlFor={burger.id} className="flex-1">
              <span className="block text-sm font-medium text-gray-900">
                {burger.name}
              </span>
              <span className="block text-sm text-gray-500">
                {burger.description}
              </span>
            </label>
          </div>
        ))}
        {validationErrors.selectedBurgers && (
          <p className="text-red-500 text-sm mt-2">
            {validationErrors.selectedBurgers}
          </p>
        )}
      </div>
    </div>
  );
};
