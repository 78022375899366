import { Link } from "react-router-dom";
import { useState } from "react";

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="fixed w-full bg-black z-50">
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex justify-between items-center h-[72px]">
          <div>
            <Link to="/">
              <img
                src="/images/logo_white.png"
                alt="Burgerlijk"
                className="h-12"
              />
            </Link>
          </div>

          {/* Hamburger Menu Button - Only visible on mobile */}
          <button
            className="lg:hidden text-white p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>

          {/* Desktop Navigation - Hidden on mobile */}
          <div className="hidden lg:flex space-x-12">
            <Link to="/" className="nav-link">
              HOME
            </Link>
            <Link to="/offerte" className="nav-link">
              OFFERTE
            </Link>
            <Link to="/werking-info" className="nav-link">
              WERKING & INFO
            </Link>
          </div>
        </div>

        {/* Mobile Navigation Overlay */}
        <div
          className={`
          lg:hidden fixed inset-0 bg-black bg-opacity-95 z-40
          transition-transform duration-300 ease-in-out
          ${isMenuOpen ? "translate-x-0" : "translate-x-full"}
        `}
        >
          <div className="flex flex-col items-center justify-center h-full space-y-8">
            <Link
              to="/"
              className="nav-link text-xl"
              onClick={() => setIsMenuOpen(false)}
            >
              HOME
            </Link>
            <Link
              to="/offerte"
              className="nav-link text-xl"
              onClick={() => setIsMenuOpen(false)}
            >
              OFFERTE
            </Link>
            <Link
              to="/werking-info"
              className="nav-link text-xl"
              onClick={() => setIsMenuOpen(false)}
            >
              WERKING & INFO
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
