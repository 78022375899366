import { useState, useEffect } from "react";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { Link, useLocation } from "react-router-dom";
import CookieConsent from "./components/CookieConsent";
import { initGA, logPageView } from "./utils/analytics";

export default function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      setCookiesAccepted(true);
      initGA();
    }
  }, []);

  useEffect(() => {
    if (cookiesAccepted) {
      logPageView();
    }
  }, [location, cookiesAccepted]);

  const handleCookieAccept = () => {
    setCookiesAccepted(true);
    localStorage.setItem('cookieConsent', 'true');
    initGA();
    logPageView();
  };

  return (
    <div className="min-h-screen bg-white">
      <Navigation />
      {!cookiesAccepted && <CookieConsent onAccept={handleCookieAccept} />}
      <div className={!cookiesAccepted ? 'mb-16' : ''}>
        {/* Hero Section */}
        <section className="hero-section">
          <div className="z-10 transform -translate-y-8">
            <h1 className="text-[100px] font-bold tracking-wide mb-4">
              Burgerlijk
            </h1>
            <p className="text-2xl tracking-[0.3em] opacity-80 font-light font-['Oswald']">
              On Tour
            </p>
            <div className="w-24 h-0.5 bg-brand-yellow mx-auto mt-6"></div>

            {/* New CTA Section */}
            <div className="mt-12 text-center">
              <p className="text-2xl mb-6 text-white font-['Oswald'] drop-shadow-lg">
                Premium burgers voor uw evenement?
              </p>
              <Link
                to="/offerte"
                className="inline-block px-8 py-4 bg-brand-yellow text-white font-['Oswald'] text-xl rounded-lg hover:bg-brand-yellow-600 transition-colors transform hover:scale-105 duration-200 shadow-lg"
              >
                VRAAG NU EEN OFFERTE AAN
              </Link>
            </div>
          </div>
        </section>

        {/* Our Story Section */}
        <section className="content-section py-12">
          <div className="max-w-6xl mx-auto">
            <div className="text-center mb-6">
              <h2 className="text-4xl font-bold text-gray-900 tracking-wide mb-3 font-['Oswald']">
                Welkom bij Burgerlijk
              </h2>
              <div className="w-24 h-0.5 bg-brand-yellow mx-auto mb-3"></div>
              <p className="text-2xl text-gray-600 font-['Oswald']">
                De Premium Burgers die je niet wilt missen!
              </p>
            </div>

            <div className="bg-white rounded-2xl p-8 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <div>
                  <img
                    src="/images/bread.jpg"
                    alt="Fresh bread"
                    className="w-full h-[400px] object-cover rounded-lg shadow-md"
                  />
                </div>
                <div className="space-y-4">
                  <p className="text-lg">
                    Ons verhaal begint in 2013, toen we Eethuis De Ploatse openden
                    in het hart van Deerlijk. Met de wens om een gezellige
                    ontmoetingsplek te creëren, waar iedereen welkom is, begonnen
                    we met het serveren van lunch, tea room en diner. Of je nu
                    kwam voor een snelle lunch of rustig wilde blijven hangen met
                    een drankje, alles was mogelijk. Al snel werd onze menukaart
                    bekend om onze premium burgers, en we groeiden uit tot dé
                    burgerzaak van de streek.
                  </p>
                  <p className="text-lg">
                    Onze liefde voor de echte premium burger werd steeds groter en
                    de uitdaging om steeds weer een nóg betere burger te creëren,
                    bleef ons drijven. Toen de COVID-periode aanbrak, pasten we
                    ons snel aan en boden we alleen nog take-away burgers aan.
                    Maar dit bracht ook iets moois voort: Burgerlijk was geboren!
                    Sinds 2022 kun je bij ons terecht voor een burgerervaring die
                    elke dag, 7 dagen per week, te beleven is.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* What We Offer Section */}
        <section className="content-section py-12">
          <div className="max-w-6xl mx-auto">
            <h3 className="text-3xl font-bold text-gray-900 tracking-wide mb-6 font-['Oswald'] text-center">
              Wat bieden we aan?
            </h3>
            <div className="bg-white rounded-2xl p-8 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
              <div className="space-y-4">
                <p className="text-lg">
                  Bij Burgerlijk kies je uit maar liefst 25 verschillende burgers,
                  naast een uitgebreid aanbod van side dishes, pasta's en meer.
                  Wij zorgen ervoor dat je altijd iets kunt vinden dat je
                  smaakpapillen verwent!
                </p>
                <p className="text-lg font-semibold">
                  Bestellen bij Burgerlijk is eenvoudig:
                </p>
                <ul className="list-disc pl-6 space-y-2 text-lg">
                  <li>Bezoek onze webshop en ontdek onze suggesties!</li>
                  <li>
                    Bestel online voor thuisbezorging of haal je bestelling zelf
                    op.
                  </li>
                  <li>
                    Wil je liever ter plaatse genieten van je burger? Geen
                    probleem! Wij hebben 20 zitplaatsen beschikbaar voor een
                    gezellige lunch of diner.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Burgerlijk Boxen Section */}
        <section className="content-section py-12">
          <div className="max-w-6xl mx-auto">
            <h3 className="text-3xl font-bold text-gray-900 tracking-wide mb-6 font-['Oswald'] text-center">
              Burgerlijk Boxen: De ultieme burgerervaring voor thuis
            </h3>
            <div className="bg-white rounded-2xl p-8 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
              <p className="text-lg">
                Ben je op zoek naar een gezellige activiteit met vrienden of
                familie? Onze Burgerlijk Boxen zijn perfect voor een avond vol
                smaaksensaties. Elke box bevat alle ingrediënten om 3
                verschillende degustatieburgers per persoon te maken. Dus, wil je
                zelf aan de slag als burgerbakker? Wij leveren je alles wat je
                nodig hebt om je gasten, vrienden of familie te verrassen met de
                lekkerste premium burgers!
              </p>
            </div>
          </div>
        </section>

        {/* Foodtruck Section */}
        <section className="content-section py-12">
          <div className="max-w-6xl mx-auto">
            <h3 className="text-3xl font-bold text-gray-900 tracking-wide mb-6 font-['Oswald'] text-center">
              Onze Foodtruck: Burgers op locatie!
            </h3>
            <div className="bg-white rounded-2xl p-8 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
              <div className="space-y-4">
                <p className="text-lg">
                  Sinds 2018 hebben we een foodtruck waarmee we onze heerlijke
                  burgers naar jouw locatie brengen. Of het nu gaat om een
                  verjaardagsfeest, communie, babyborrel, teambuilding, trouwfeest
                  of een ander evenement, onze foodtruck maakt van elke
                  gelegenheid iets bijzonders.
                </p>
                <p className="text-lg">
                  Benieuwd naar wat we voor jouw event kunnen betekenen? Ga naar
                  On Tour en vraag eenvoudig je offerte aan. Wij zorgen ervoor dat
                  je gasten genieten van de beste burgers, direct van de grill!
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Closing Section */}
        <section className="content-section py-12">
          <div className="max-w-6xl mx-auto">
            <div className="bg-white rounded-2xl p-8 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
              <div className="space-y-4">
                <h3 className="text-3xl font-bold text-gray-900 tracking-wide mb-4 font-['Oswald'] text-center">
                  Kom langs en ervaar de passie voor premium burgers!
                </h3>
                <p className="text-lg text-center">
                  We kijken ernaar uit je te verwelkomen in Burgerlijk. Of je nu
                  komt voor een snelle maaltijd, een gezellige avond met vrienden,
                  of een speciaal event, bij ons ben je altijd verzekerd van de
                  allerbeste burgers, bereid met de meest verse ingrediënten.
                </p>
                <p className="text-lg text-center">
                  We zien je snel bij Burgerlijk, of aan onze foodtruck.
                </p>
                <div className="text-center mt-6">
                  <p className="font-semibold text-xl">Groetjes</p>
                  <p className="text-xl">Jana Bossuyt & Niels Lanckbeen</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Bottom CTA Section */}
        <section className="content-section py-12">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4 font-['Oswald']">
              Klaar om uw evenement onvergetelijk te maken?
            </h2>
            <p className="text-xl mb-6 text-gray-600">
              Laat ons uw gasten verwennen met onze premium burgers
            </p>
            <Link
              to="/offerte"
              className="inline-block px-8 py-4 bg-brand-yellow text-white font-['Oswald'] text-xl rounded-lg hover:bg-brand-yellow-600 transition-colors transform hover:scale-105 duration-200 shadow-lg"
            >
              VRAAG NU EEN OFFERTE AAN
            </Link>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
