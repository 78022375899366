import React from "react";
import { FormData } from "../types";

interface AddressSectionProps {
  title: string;
  type: "event" | "factuur";
  formData: FormData;
  disabled?: boolean;
  onAddressChange: (field: keyof FormData, value: string) => void;
  extraContent?: React.ReactNode;
  validationErrors?: { [key: string]: string };
}

export const AddressSection: React.FC<AddressSectionProps> = ({
  title,
  type,
  formData,
  disabled = false,
  onAddressChange,
  extraContent,
  validationErrors = {},
}) => {
  const prefix = type === "event" ? "event" : "factuur";
  const isRequired =
    type === "event" || (type === "factuur" && !formData.sameAsEvent); // Fields are required for event and factuur (when not same as event)

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-gray-900 font-['Oswald']">
          {title}
        </h3>
        {extraContent}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {type === "event" && (
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Naam Locatie <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="eventLocatieNaam"
              value={formData.eventLocatieNaam}
              onChange={(e) =>
                onAddressChange("eventLocatieNaam", e.target.value)
              }
              placeholder="Thuis, 'OC De Kern Deerlijk', ..."
              disabled={disabled}
              className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
                validationErrors.eventLocatieNaam
                  ? "border-red-500"
                  : "border-gray-300"
              } ${disabled ? "bg-gray-100" : ""}`}
            />
            {validationErrors.eventLocatieNaam && (
              <p className="text-red-500 text-sm mt-1">
                {validationErrors.eventLocatieNaam}
              </p>
            )}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Straat {isRequired && <span className="text-red-500">*</span>}
          </label>
          <input
            type="text"
            name={`${prefix}Straat`}
            value={formData[`${prefix}Straat` as keyof FormData] as string}
            onChange={(e) =>
              onAddressChange(
                `${prefix}Straat` as keyof FormData,
                e.target.value
              )
            }
            disabled={disabled}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors[`${prefix}Straat`]
                ? "border-red-500"
                : "border-gray-300"
            } ${disabled ? "bg-gray-100" : ""}`}
          />
          {validationErrors[`${prefix}Straat`] && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors[`${prefix}Straat`]}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Huisnummer {isRequired && <span className="text-red-500">*</span>}
          </label>
          <input
            type="text"
            name={`${prefix}Huisnummer`}
            value={formData[`${prefix}Huisnummer` as keyof FormData] as string}
            onChange={(e) =>
              onAddressChange(
                `${prefix}Huisnummer` as keyof FormData,
                e.target.value
              )
            }
            disabled={disabled}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors[`${prefix}Huisnummer`]
                ? "border-red-500"
                : "border-gray-300"
            } ${disabled ? "bg-gray-100" : ""}`}
          />
          {validationErrors[`${prefix}Huisnummer`] && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors[`${prefix}Huisnummer`]}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Postcode {isRequired && <span className="text-red-500">*</span>}
          </label>
          <input
            type="text"
            name={`${prefix}Postcode`}
            value={formData[`${prefix}Postcode` as keyof FormData] as string}
            onChange={(e) =>
              onAddressChange(
                `${prefix}Postcode` as keyof FormData,
                e.target.value
              )
            }
            disabled={disabled}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors[`${prefix}Postcode`]
                ? "border-red-500"
                : "border-gray-300"
            } ${disabled ? "bg-gray-100" : ""}`}
          />
          {validationErrors[`${prefix}Postcode`] && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors[`${prefix}Postcode`]}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Gemeente {isRequired && <span className="text-red-500">*</span>}
          </label>
          <input
            type="text"
            name={`${prefix}Gemeente`}
            value={formData[`${prefix}Gemeente` as keyof FormData] as string}
            onChange={(e) =>
              onAddressChange(
                `${prefix}Gemeente` as keyof FormData,
                e.target.value
              )
            }
            disabled={disabled}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors[`${prefix}Gemeente`]
                ? "border-red-500"
                : "border-gray-300"
            } ${disabled ? "bg-gray-100" : ""}`}
          />
          {validationErrors[`${prefix}Gemeente`] && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors[`${prefix}Gemeente`]}
            </p>
          )}
        </div>

        {type === "factuur" && (
          <>
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bedrijfsnaam (indien van toepassing)
              </label>
              <input
                type="text"
                name="bedrijfsnaam"
                value={formData.bedrijfsnaam}
                onChange={(e) =>
                  onAddressChange("bedrijfsnaam", e.target.value)
                }
                className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
                  validationErrors.bedrijfsnaam
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
              />
              {validationErrors.bedrijfsnaam && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors.bedrijfsnaam}
                </p>
              )}
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                BTW Nummer
              </label>
              <input
                type="text"
                name="btwnummer"
                value={formData.btwnummer}
                onChange={(e) => onAddressChange("btwnummer", e.target.value)}
                className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
                  validationErrors.btwnummer
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
              />
              {validationErrors.btwnummer && (
                <p className="text-red-500 text-sm mt-1">
                  {validationErrors.btwnummer}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
