import { useState, useEffect } from "react";
import { FormData } from "../pages/Offerte/types";
import { FORMULA_OPTIONS, DESSERT_OPTIONS } from "../pages/Offerte/constants";
import { getBurgerOptions } from "../services/api";
import { Link } from "react-router-dom";

interface SummaryPopoverProps {
  formData: FormData;
  onClose: () => void;
  onConfirm: () => void;
  isSubmitting?: boolean;
  error?: string | null;
}

export default function SummaryPopover({
  formData,
  onClose,
  onConfirm,
  isSubmitting = false,
  error = null,
}: SummaryPopoverProps) {
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [burgerOptions, setBurgerOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);

  useEffect(() => {
    const loadBurgerOptions = async () => {
      try {
        const options = await getBurgerOptions();
        setBurgerOptions(options);
      } catch (err) {
        console.error("Failed to load burger options:", err);
        // You might want to handle this error more gracefully
      }
    };

    loadBurgerOptions();
  }, []);

  const getBurgerName = (burgerId: string) => {
    return (
      burgerOptions.find((burger) => burger.id === burgerId)?.name || burgerId
    );
  };

  const getFormulaName = (formulaId: string) => {
    return (
      FORMULA_OPTIONS.find((formula) => formula.id === formulaId)?.name ||
      formulaId
    );
  };

  const getDessertName = (dessertId: string) => {
    return (
      DESSERT_OPTIONS.find((dessert) => dessert.id === dessertId)?.name ||
      dessertId
    );
  };

  const totalPeople = formData.volwassenen + formData.kinderen;
  const vegetarischCount = formData.vegetarisch;

  const totalDesserts = Object.values(formData.desserts).reduce(
    (sum, count) => sum + count,
    0
  );
  const hasDesserts = totalDesserts > 0;
  const hasEnoughDesserts = totalDesserts >= totalPeople * 0.5;

  const formatPrice = (price: number) => {
    return `€${price.toFixed(2).replace(".", ",")}`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[80vh] overflow-y-auto relative">
        {/* Close button */}
        <button
          onClick={onClose}
          disabled={isSubmitting}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 disabled:opacity-50"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-2xl font-bold mb-6 font-['Oswald']">
          SAMENVATTING OFFERTE
        </h2>

        <div className="space-y-6">
          {/* Event Details */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-brand-yellow">
              Event Details
            </h3>
            <div className="space-y-4">
              <p>
                <span className="font-medium">Formule:</span>{" "}
                {getFormulaName(formData.formula)}
              </p>

              {/* People Count Summary */}
              <div className="bg-white p-3 rounded border border-gray-200">
                <p className="font-medium mb-1">
                  Aantal personen: {totalPeople} totaal (waarvan{" "}
                  {vegetarischCount} vegetarisch)
                </p>
                <ul className="text-sm text-gray-600 space-y-1">
                  <li>• {formData.volwassenen} volwassenen</li>
                  <li>• {formData.kinderen} kinderen</li>
                </ul>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <p>
                  <span className="font-medium">Datum:</span>{" "}
                  {new Date(formData.datum).toLocaleDateString()}
                </p>
                <p>
                  <span className="font-medium">Tijd:</span>{" "}
                  {formData.startTijd} - {formData.eindTijd}
                </p>
              </div>
            </div>
          </div>

          {/* Event Address */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-brand-yellow">
              Event Adres
            </h3>
            <div className="space-y-1">
              <p className="font-medium">{formData.eventLocatieNaam}</p>
              <p className="font-medium">
                {formData.eventNaam} {formData.eventVoornaam}
              </p>
              <p>
                {formData.eventStraat} {formData.eventHuisnummer}
              </p>
              <p>
                {formData.eventPostcode} {formData.eventGemeente}
              </p>
              <p className="text-gray-600">{formData.eventEmail}</p>
              <p className="text-gray-600">Tel: {formData.eventPhoneNumber}</p>
            </div>
          </div>

          {/* Billing Details */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-brand-yellow">
              Factuur Gegevens
            </h3>
            <div className="space-y-1">
              {formData.bedrijfsnaam && (
                <p className="font-medium">{formData.bedrijfsnaam}</p>
              )}
              <p>
                {formData.factuurStraat} {formData.factuurHuisnummer}
              </p>
              <p>
                {formData.factuurPostcode} {formData.factuurGemeente}
              </p>
              {formData.btwnummer && (
                <p className="text-gray-600 mt-2">BTW: {formData.btwnummer}</p>
              )}
            </div>
          </div>

          {/* Selected Burgers */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-brand-yellow">
              Gekozen Burgers
            </h3>
            <ul className="space-y-2">
              {formData.selectedBurgers.map((burger) => (
                <li key={burger} className="flex items-center">
                  <span className="w-4 h-4 bg-brand-yellow rounded-full mr-2"></span>
                  {getBurgerName(burger)}
                </li>
              ))}
            </ul>
          </div>

          {/* Desserts Section */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-brand-yellow">
              Desserts
            </h3>
            {hasDesserts ? (
              <>
                <div className="space-y-2">
                  {Object.entries(formData.desserts).map(
                    ([dessertId, quantity]) => {
                      if (quantity > 0) {
                        const dessert = DESSERT_OPTIONS.find(
                          (d) => d.id === dessertId
                        );
                        return (
                          <div
                            key={dessertId}
                            className="flex justify-between items-center"
                          >
                            <span>{getDessertName(dessertId)}</span>
                            <div className="text-right">
                              <span className="text-gray-600 mr-4">
                                {formatPrice(dessert?.price || 0)}
                              </span>
                              <span className="font-medium">{quantity}x</span>
                            </div>
                          </div>
                        );
                      }
                      return null;
                    }
                  )}
                </div>
                {!hasEnoughDesserts && (
                  <div className="mt-4 bg-amber-50 border border-amber-200 rounded-md p-3">
                    <p className="text-amber-800 text-sm">
                      Het lijkt erop dat niet iedereen een dessert heeft. Bent u
                      iets vergeten?{" "}
                      <button
                        onClick={onClose}
                        className="text-brand-yellow hover:text-brand-yellow-600 font-medium underline focus:outline-none"
                      >
                        Voeg meer toe
                      </button>
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="bg-amber-50 border border-amber-200 rounded-md p-3">
                <p className="text-amber-800 text-sm">
                  Bent u de desserts vergeten?{" "}
                  <button
                    onClick={onClose}
                    className="text-brand-yellow hover:text-brand-yellow-600 font-medium underline focus:outline-none"
                  >
                    Voeg ze nu toe
                  </button>
                </p>
              </div>
            )}
          </div>

          {/* Error Message */}
          {error && (
            <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded-lg">
              {error}
            </div>
          )}

          {/* Privacy Policy Checkbox */}
          <div className="flex items-center space-x-2 mt-4">
            <input
              type="checkbox"
              id="privacyPolicy"
              checked={privacyPolicyAccepted}
              onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
              className="h-4 w-4 text-brand-yellow border-gray-300 rounded focus:ring-brand-yellow"
            />
            <label htmlFor="privacyPolicy" className="text-sm text-gray-600">
              Ik ga akkoord met de{" "}
              <Link
                to="/privacy-policy"
                target="_blank"
                className="text-brand-yellow hover:underline"
              >
                privacy policy
              </Link>
            </label>
          </div>

          {/* Buttons */}
          <div className="flex justify-end space-x-4 mt-8">
            <button
              onClick={onClose}
              disabled={isSubmitting}
              className="px-6 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              Annuleren
            </button>
            <button
              onClick={onConfirm}
              disabled={isSubmitting || !privacyPolicyAccepted}
              className="px-6 py-2 bg-brand-yellow text-white rounded-md hover:bg-brand-yellow-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors flex items-center space-x-2"
            >
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Bezig met verzenden...</span>
                </>
              ) : (
                <span>Bevestigen</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
