import axios from 'axios';
import { FormData } from '../types';

declare global {
  interface Window {
    RUNTIME_CONFIG?: {
      API_URL: string;
      CLIENT_URL: string;
      BACKOFFICE_URL: string;
    }
  }
}

const getBaseUrl = () => {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_API_URL
  }
  return window.RUNTIME_CONFIG?.API_URL
}

const api = axios.create({
  baseURL: `${getBaseUrl()}/public`
});

export const submitOfferte = async (formData: FormData): Promise<{ success: boolean; message: string }> => {
  try {
    // Transform the form data to match the backend's expected format
    const transformedData = {
      formula: formData.formula,
      volwassenen: Number(formData.volwassenen),
      kinderen: Number(formData.kinderen),
      vegetarisch: Number(formData.vegetarisch),
      keuze: formData.keuze,
      datum: formData.datum,
      startTijd: formData.startTijd,
      eindTijd: formData.eindTijd,
      
      eventLocatieNaam: formData.eventLocatieNaam, // Added this field
      eventNaam: formData.eventNaam,
      eventVoornaam: formData.eventVoornaam,
      eventStraat: formData.eventStraat,
      eventHuisnummer: formData.eventHuisnummer,
      eventPostcode: formData.eventPostcode,
      eventGemeente: formData.eventGemeente,
      eventEmail: formData.eventEmail,
      eventPhoneNumber: formData.eventPhoneNumber,
      
      factuurNaam: formData.eventNaam,
      factuurVoornaam: formData.eventVoornaam,
      factuurStraat: formData.factuurStraat,
      factuurHuisnummer: formData.factuurHuisnummer,
      factuurPostcode: formData.factuurPostcode,
      factuurGemeente: formData.factuurGemeente,
      btwnummer: formData.btwnummer,
      bedrijfsnaam: formData.bedrijfsnaam,
      
      selectedBurgers: formData.selectedBurgers,
      desserts: formData.desserts,
      opmerkingen: formData.opmerkingen,
      sameAsEvent: formData.sameAsEvent
    };

    const response = await api.post('/submit-form', transformedData);
    
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message || 'Offerte aanvraag succesvol verzonden!'
      };
    } else {
      throw new Error(response.data.error || 'Er is een fout opgetreden');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    if (axios.isAxiosError(error) && error.response?.data?.error) {
      throw new Error(error.response.data.error);
    }
    throw new Error('Er is een fout opgetreden bij het verzenden van de offerte');
  }
};

export const getSubmissions = async () => {
  try {
    const response = await api.get('/submissions');
    return response.data;
  } catch (error) {
    console.error('Error fetching submissions:', error);
    throw new Error('Failed to fetch submissions');
  }
};
