import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navigation />
      
      <section className="hero-section">
        <div className="z-10 transform -translate-y-8">
          <h1 className="text-[100px] font-bold tracking-wide mb-4">
            PRIVACY POLICY
          </h1>
          <div className="w-24 h-0.5 bg-brand-yellow mx-auto mt-6"></div>
        </div>
      </section>

      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-2xl p-12 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
            <h2 className="text-3xl font-bold mb-6">Privacy Policy</h2>
            
            <h3 className="text-2xl font-bold mt-8 mb-4">1. Algemeen</h3>
            <p className="mb-4">
              De Ploatse Comm. V., met maatschappelijke zetel te Hoogstraat 95, 8540 Deerlijk en ingeschreven bij de KBO onder nummer BE0692.529.718 (hierna "Burgerlijk on Tour") vindt de bescherming van de persoonlijke levenssfeer uiterst belangrijk. Burgerlijk on Tour wil haar klanten en de gebruikers van haar diensten zoveel mogelijk informeren, respecteren en controle geven over wat er met hun gegevens gebeurt. Burgerlijk on Tour wil haar klantengegevens veilig, respectvol en als goede huisvader beheren en gebruiken om haar klanten beter van dienst te kunnen zijn en een zo goed mogelijke ervaring te bieden. Burgerlijk on Tour vertrekt dan ook vanuit het principe dat iedereen controle moet hebben over zijn of haar persoonsgegevens.
            </p>
            <p className="mb-4">
              Burgerlijk on Tour nodigt haar klanten uit de tijd te nemen om deze Privacy Policy goed door te nemen, samen met de Algemene Voorwaarden en eventuele andere voorwaarden die van toepassing kunnen zijn op haar producten en diensten.
            </p>

            <h3 className="text-2xl font-bold mt-8 mb-4">2. Toepassing</h3>
            <p className="mb-4">
              Deze Privacy Policy is van toepassing op al onze klanten (huidige, voormalige en toekomstige) en op alle bezoekers van de Burgerlijk on Tour website(s).
            </p>
            <p className="mb-4">
              De Europese Algemene Verordening Gegevensbescherming 2016/679 van 27 april 2016 ("Algemene Verordening Gegegevensbescherming"), de wet van 30 juli 2018 ("Wet bescherming persoonsgegevens"), de wet van 13 juni 2005 ("Wet Elektronische Communicatie") en de bijhorende uitvoeringsbesluiten, alsook enige toekomstige wijzigingen hiervan, regelen de bescherming van uw persoonsgegevens.
            </p>

            <h3 className="text-2xl font-bold mt-8 mb-4">3. Verwerking van persoonsgegevens en verantwoordelijkheden</h3>
            <p className="mb-4">
              Onder "persoonsgegevens" wordt het volgende verstaan: alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon ("de betrokkene"), met dien verstande dat als identificeerbaar wordt beschouwd een natuurlijke persoon die direct of indirect kan worden geïdentificeerd, met name aan de hand van een identificator zoals een naam, een identificatienummer, locatiegegevens, een online identificator of van een of meer elementen die kenmerkend zijn voor de fysieke, fysiologische, genetische, psychische, economische, culturele of sociale identiteit van die natuurlijke persoon.
            </p>
            <p className="mb-4">
              De persoonsgegevens die Burgerlijk on Tour verzamelt en verwerkt, betreffen in de eerste plaats de gegevens die de klant zelf invoert via de verschillende pagina's van onze website(s) en die Burgerlijk on Tour verkrijgt door uw gebruik van onze website(s) en/of onze producten en/of diensten.
            </p>

            <h3 className="text-2xl font-bold mt-8 mb-4">4. Doeleinden</h3>
            <p className="mb-4">
              Burgerlijk on Tour verwerkt persoonsgegevens voor diverse doeleinden, waarbij telkens enkel die gegevens worden verwerkt die noodzakelijk zijn om het beoogde doel te realiseren. Zo gebruiken wij persoonsgegevens:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Wanneer wij toestemming hebben gekregen;</li>
              <li>In het kader van de voorbereiding of uitvoering van ons contract;</li>
              <li>Om te voldoen aan de wettelijke of reglementaire bepalingen waaraan wij zijn onderworpen;</li>
              <li>Wanneer Burgerlijk on Tour daarvoor een gerechtvaardigd belang heeft, zoals bijvoorbeeld het voeren van direct marketing, het voorkomen van fraude, het beheren van interne administratie of het bewaken van een passende netwerk- en informatiebeveiliging.</li>
            </ul>
            <p className="mb-4">
              Meer specifiek verwerkt Burgerlijk on Tour uw persoonsgegevens voor het boeken van foodtrucks. We gebruiken de verzamelde gegevens om uw aanvraag te verwerken en de beste service te bieden voor het organiseren van uw evenement met onze foodtrucks.
            </p>
            <h3 className="text-2xl font-bold mt-8 mb-4">5. Cookies en Google Analytics</h3>
            <p className="mb-4">
              Burgerlijk on Tour maakt gebruik van cookies om uw ervaring op onze website te verbeteren en om inzicht te krijgen in hoe onze website wordt gebruikt. Cookies zijn kleine tekstbestanden die op uw apparaat worden opgeslagen wanneer u onze website bezoekt.
            </p>
            <p className="mb-4">
              We gebruiken de volgende soorten cookies:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Essentiële cookies: Deze zijn noodzakelijk voor het functioneren van de website.</li>
              <li>Analytische cookies: We gebruiken Google Analytics om inzicht te krijgen in hoe bezoekers onze website gebruiken. Dit helpt ons om onze diensten te verbeteren.</li>
            </ul>
            <p className="mb-4">
              Google Analytics verzamelt geanonimiseerde gegevens over uw gebruik van onze website. Deze gegevens worden niet gebruikt om u persoonlijk te identificeren. U kunt het gebruik van cookies beheren via uw browserinstellingen. Houd er rekening mee dat het uitschakelen van cookies de functionaliteit van onze website kan beïnvloeden.
            </p>
            <p className="mb-4">
              Door onze website te blijven gebruiken, stemt u in met ons gebruik van cookies zoals beschreven in deze Privacy Policy.
            </p>

            <h3 className="text-2xl font-bold mt-8 mb-4">6. Beveiliging</h3>
            <p className="mb-4">
              Burgerlijk on Tour streeft er te allen tijde naar om persoonsgegevens en privacy te beschermen, zowel in de fysieke kantoren als op het netwerk van Burgerlijk on Tour. We maken gebruik van HTTPS-beveiliging voor onze website en nemen passende organisatorische en technische maatregelen om uw persoonsgegevens te beveiligen en uw privacy te respecteren.
            </p>
            <p className="mb-4">
              Indien er zich een gegevenslek zou voordoen met ongunstige gevolgen voor persoonsgegevens, dan wordt de klant persoonlijk verwittigd in de door de wet voorziene omstandigheden.
            </p>

            <h3 className="text-2xl font-bold mt-8 mb-4">6. Verstrekken van gegevens aan derden</h3>
            <p className="mb-4">
              Burgerlijk on Tour verkoopt geen persoonsgegevens aan derde partijen noch worden deze doorgegeven aan derden tenzij:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Dit nodig is voor onze dienstverlening (bijvoorbeeld aan onze foodtruck partners);</li>
              <li>Er een wettelijke verplichting is;</li>
              <li>Er een gerechtvaardigd belang is voor Burgerlijk on Tour of de betrokken derde;</li>
              <li>Burgerlijk on Tour hiervoor uw toestemming heeft gekregen.</li>
            </ul>

            <h3 className="text-2xl font-bold mt-8 mb-4">7. Rechten van de betrokkene</h3>
            <p className="mb-4">
              U heeft het recht om:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Inzage te krijgen in de persoonsgegevens die Burgerlijk on Tour over u verwerkt;</li>
              <li>Onjuiste gegevens te laten verbeteren;</li>
              <li>In bepaalde omstandigheden te verzoeken uw gegevens te laten verwijderen;</li>
              <li>De verwerking van uw gegevens te laten beperken;</li>
              <li>Bezwaar te maken tegen de verwerking van uw persoonsgegevens;</li>
              <li>Uw gegevens in een gestructureerde, gangbare en machineleesbare vorm te verkrijgen en aan een andere verwerkingsverantwoordelijke over te dragen (dataportabiliteit).</li>
            </ul>

            <h3 className="text-2xl font-bold mt-8 mb-4">8. Contactgegevens</h3>
            <p className="mb-4">
              Voor vragen of verzoeken over deze Privacy Policy of over uw persoonsgegevens kunt u contact opnemen via:
            </p>
            <p className="mb-2">De Ploatse Comm. V.</p>
            <p className="mb-2">Hoogstraat 95</p>
            <p className="mb-2">8540 Deerlijk</p>
            <p className="mb-2">BE0692.529.718</p>
            <p className="mb-4">
              E-mail: <a href="mailto:privacy@burgerlijk.com" className="text-brand-yellow hover:underline">privacy@burgerlijk.com</a>
            </p>

            <h3 className="text-2xl font-bold mt-8 mb-4">9. Geschillen</h3>
            <p className="mb-4">
              Bij geschillen is de bevoegde rechtbank:
            </p>
            <p className="mb-2">Ondernemingsrechtbank Gent</p>
            <p className="mb-4">Afdeling Kortrijk</p>

            <h3 className="text-2xl font-bold mt-8 mb-4">10. Wijzigingen</h3>
            <p className="mb-4">
              Burgerlijk on Tour behoudt zich het recht voor om deze Privacy Policy aan te passen. Wijzigingen zullen steeds voorafgaand aan de inwerkingtreding ervan op deze website worden gepubliceerd. Het verdient aanbeveling om deze Policy geregeld te raadplegen, zodat u van deze wijzigingen op de hoogte bent.
            </p>

            <p className="mt-8 text-sm text-gray-600">
              Laatste update: {new Date().toLocaleDateString()}
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
