import React from 'react';
import { FormData } from '../types';
import { DESSERT_OPTIONS } from '../constants';

interface DessertSectionProps {
  formData: FormData;
  onDessertCountChange: (dessertId: string, count: number) => void;
}

export const DessertSection: React.FC<DessertSectionProps> = ({
  formData,
  onDessertCountChange,
}) => {
  const formatPrice = (price: number) => {
    return `€${price.toFixed(2).replace('.', ',')}`;
  };

  const getTotalDesserts = () => {
    return Object.values(formData.desserts).reduce((sum, count) => sum + count, 0);
  };

  const handleQuantityChange = (dessertId: string, delta: number) => {
    const currentValue = formData.desserts[dessertId as keyof typeof formData.desserts];
    const newValue = Math.max(0, currentValue + delta);
    onDessertCountChange(dessertId, newValue);
  };

  const totalDesserts = getTotalDesserts();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-gray-900 font-['Oswald']">
          DESSERT
        </h3>
        {totalDesserts > 0 && (
          <span className="text-sm text-gray-600">
            Totaal: {totalDesserts} dessert{totalDesserts !== 1 ? 's' : ''}
          </span>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {DESSERT_OPTIONS.map((dessert) => (
          <div
            key={dessert.id}
            className="bg-white rounded-lg p-4 border border-gray-200 hover:border-brand-yellow transition-colors duration-200 shadow-sm"
          >
            <div className="flex justify-between items-start mb-2">
              <div>
                <h4 className="font-medium text-gray-900">{dessert.name}</h4>
                <span className="text-brand-yellow font-medium">
                  {formatPrice(dessert.price)}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  onClick={() => handleQuantityChange(dessert.id, -1)}
                  className="w-8 h-8 flex items-center justify-center rounded-full border border-gray-300 hover:border-brand-yellow text-gray-600 hover:text-brand-yellow transition-colors"
                  disabled={formData.desserts[dessert.id as keyof typeof formData.desserts] === 0}
                >
                  -
                </button>
                <input
                  type="number"
                  min="0"
                  value={formData.desserts[dessert.id as keyof typeof formData.desserts]}
                  onChange={(e) =>
                    onDessertCountChange(dessert.id, parseInt(e.target.value) || 0)
                  }
                  className="w-16 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow text-center"
                />
                <button
                  type="button"
                  onClick={() => handleQuantityChange(dessert.id, 1)}
                  className="w-8 h-8 flex items-center justify-center rounded-full border border-gray-300 hover:border-brand-yellow text-gray-600 hover:text-brand-yellow transition-colors"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
