import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

function WerkingInfo() {
  return (
    <div className="min-h-screen bg-white">
      <Navigation />

      {/* Hero Section */}
      <section className="hero-section">
        <div className="z-10 transform -translate-y-8">
          <h1 className="text-[100px] font-bold tracking-wide mb-4">
            WERKING & INFO
          </h1>
          <p className="text-2xl tracking-[0.3em] opacity-80 font-light font-['Oswald']">
            Hoe werkt het?
          </p>
          <div className="w-24 h-0.5 bg-brand-yellow mx-auto mt-6"></div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="content-section py-24">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 tracking-wide mb-6 font-['Oswald']">
              HOE WERKT HET?
            </h2>
            <p className="text-gray-600 font-['Open_Sans'] max-w-2xl mx-auto">
              Ontdek hoe wij uw evenement kunnen voorzien van heerlijke premium
              burgers.
            </p>
          </div>

          <div className="grid gap-16">
            <div className="bg-white rounded-2xl p-12 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
              <h3 className="text-2xl font-bold mb-6 text-gray-900 font-['Oswald']">
                1. OFFERTE AANVRAGEN
              </h3>
              <p className="text-gray-600 font-['Open_Sans'] leading-relaxed">
                Vul het offerteformulier in met de details van uw evenement. Wij
                nemen binnen 48 uur contact met u op om de mogelijkheden te
                bespreken.
              </p>
            </div>

            <div className="bg-white rounded-2xl p-12 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
              <h3 className="text-2xl font-bold mb-6 text-gray-900 font-['Oswald']">
                2. MENU SAMENSTELLEN
              </h3>
              <p className="text-gray-600 font-['Open_Sans'] leading-relaxed">
                Kies uit onze premium burgers en stel een menu samen dat perfect
                past bij uw evenement. We bieden ook vegetarische opties aan.
              </p>
            </div>

            <div className="bg-white rounded-2xl p-12 shadow-[0_0_50px_0_rgba(0,0,0,0.1)]">
              <h3 className="text-2xl font-bold mb-6 text-gray-900 font-['Oswald']">
                3. FOODTRUCK OP LOCATIE
              </h3>
              <p className="text-gray-600 font-['Open_Sans'] leading-relaxed">
                Onze foodtruck komt op de afgesproken tijd naar uw locatie. We
                zorgen voor een professionele setup en bereiden alles vers ter
                plaatse.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Practical Info Section */}
      <section className="content-section bg-gray-50 py-24">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-12 tracking-wide font-['Oswald']">
            PRAKTISCHE INFO
          </h2>
          <div className="space-y-8 text-gray-700">
            <div>
              <h3 className="text-xl font-bold mb-4 text-gray-900 font-['Oswald']">
                MINIMAAL AANTAL PERSONEN
              </h3>
              <p className="font-['Open_Sans']">
                Voor een optimale service hanteren wij een minimum aantal van 20
                personen per evenement.
              </p>
            </div>

            <div>
              <h3 className="text-xl font-bold mb-4 text-gray-900 font-['Oswald']">
                LOCATIE & BEREIKBAARHEID
              </h3>
              <p className="font-['Open_Sans']">
                Onze foodtruck heeft een vlakke, verharde ondergrond nodig van
                minimaal 6x3 meter. We hebben toegang nodig tot elektriciteit (2x aparte circuits)
              </p>
            </div>
          </div>

          <div className="mt-12 text-center">
            <Link
              to="/offerte"
              className="inline-block px-8 py-4 bg-brand-yellow text-white font-['Oswald'] rounded-lg hover:bg-brand-yellow-600 transition-colors"
            >
              VRAAG EEN OFFERTE AAN
            </Link>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default WerkingInfo;
