import React from "react";
import { FormData } from "../types";

interface ContactSectionProps {
  formData: FormData;
  onAddressChange: (field: keyof FormData, value: string) => void;
  validationErrors?: { [key: string]: string };
}

export const ContactSection: React.FC<ContactSectionProps> = ({
  formData,
  onAddressChange,
  validationErrors = {},
}) => {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold text-gray-900 font-['Oswald']">
          CONTACTGEGEVENS
        </h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Naam verantwoordelijk <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eventNaam"
            value={formData.eventNaam}
            onChange={(e) => onAddressChange("eventNaam", e.target.value)}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors.eventNaam ? "border-red-500" : "border-gray-300"
            }`}
          />
          {validationErrors.eventNaam && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.eventNaam}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Voornaam verantwoordelijke <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="eventVoornaam"
            value={formData.eventVoornaam}
            onChange={(e) => onAddressChange("eventVoornaam", e.target.value)}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors.eventVoornaam
                ? "border-red-500"
                : "border-gray-300"
            }`}
          />
          {validationErrors.eventVoornaam && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.eventVoornaam}
            </p>
          )}
        </div>

        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            name="eventEmail"
            value={formData.eventEmail}
            onChange={(e) => onAddressChange("eventEmail", e.target.value)}
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors.eventEmail ? "border-red-500" : "border-gray-300"
            }`}
          />
          {validationErrors.eventEmail && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.eventEmail}
            </p>
          )}
        </div>

        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Telefoonnummer <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            name="eventPhoneNumber"
            value={formData.eventPhoneNumber}
            onChange={(e) =>
              onAddressChange("eventPhoneNumber", e.target.value)
            }
            className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:ring-brand-yellow focus:border-brand-yellow ${
              validationErrors.eventPhoneNumber
                ? "border-red-500"
                : "border-gray-300"
            }`}
          />
          {validationErrors.eventPhoneNumber && (
            <p className="text-red-500 text-sm mt-1">
              {validationErrors.eventPhoneNumber}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
