import React from 'react';
import { FormData } from '../types';
import { FORMULA_OPTIONS } from '../constants';

interface FormulaSectionProps {
  formData: FormData;
  updateFormData: (field: keyof FormData, value: string) => void;
  validationErrors?: { [key: string]: string };
}

export const FormulaSection: React.FC<FormulaSectionProps> = ({
  formData,
  updateFormData,
  validationErrors = {}
}) => {
  return (
    <div className="space-y-6">
      <h3 className="text-xl font-bold text-gray-900 font-['Oswald']">
        KEUZE VAN DE FORMULE
      </h3>
      <div className="space-y-6">
        {FORMULA_OPTIONS.map((option) => (
          <div key={option.id} className="space-y-4">
            <div className="flex items-center space-x-3">
              <input
                type="radio"
                id={option.id}
                name="formula"
                value={option.id}
                checked={formData.formula === option.id}
                onChange={(e) => updateFormData("formula", e.target.value)}
                className={`h-4 w-4 text-brand-yellow border-gray-300 focus:ring-brand-yellow ${
                  validationErrors.formula ? 'border-red-500' : ''
                }`}
              />
              <label
                htmlFor={option.id}
                className="text-sm font-medium text-gray-900"
              >
                {option.name}
              </label>
            </div>
            {formData.formula === option.id && (
              <div className="ml-7">
                <div className="bg-[#C4A77D] text-white p-4 rounded-lg">
                  {option.description}
                </div>
              </div>
            )}
          </div>
        ))}
        {validationErrors.formula && (
          <p className="text-red-500 text-sm mt-1">{validationErrors.formula}</p>
        )}
      </div>
    </div>
  );
};
