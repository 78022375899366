import { useState, useEffect } from 'react';
import { FormData } from '../types';
import { INITIAL_FORM_DATA } from '../constants';
import { submitOfferte } from '../services/api';

interface ValidationErrors {
  [key: string]: string;
}

const STORAGE_KEY = 'offerte_form_data';

export const useOfferteForm = () => {
  // Initialize form data from localStorage or use initial data
  const [formData, setFormData] = useState<FormData>(() => {
    const savedData = localStorage.getItem(STORAGE_KEY);
    if (savedData) {
      try {
        return JSON.parse(savedData);
      } catch (error) {
        console.error('Error parsing saved form data:', error);
        return INITIAL_FORM_DATA;
      }
    }
    return INITIAL_FORM_DATA;
  });

  const [showSummary, setShowSummary] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  const validateForm = (): boolean => {
    const errors: ValidationErrors = {};
    
    console.log('Validating form with data:', {
      sameAsEvent: formData.sameAsEvent,
      factuurFields: {
        straat: formData.factuurStraat,
        huisnummer: formData.factuurHuisnummer,
        postcode: formData.factuurPostcode,
        gemeente: formData.factuurGemeente
      }
    });
    
    // Required fields validation
    if (!formData.formula) {
      errors.formula = 'Selecteer een optie';
      console.log('Formula validation failed');
    }
    if (!formData.volwassenen) {
      errors.volwassenen = 'Vul het aantal volwassenen in';
    }
    // Removed kinderen validation to allow 0
    // Removed vegetarian validation to allow 0
    if (!formData.datum) {
      errors.datum = 'Selecteer een datum';
    }
    if (!formData.startTijd) {
      errors.startTijd = 'Selecteer een startuur';
    }
    if (!formData.eindTijd) {
      errors.eindTijd = 'Selecteer een einduur';
    }

    // Event address validation
    if (!formData.eventLocatieNaam) {
      errors.eventLocatieNaam = 'Vul een locatie naam in';
    }
    if (!formData.eventNaam) {
      errors.eventNaam = 'Vul een naam in';
    }
    if (!formData.eventVoornaam) {
      errors.eventVoornaam = 'Vul een voornaam in';
    }
    if (!formData.eventStraat) {
      errors.eventStraat = 'Vul een straat in';
    }
    if (!formData.eventHuisnummer) {
      errors.eventHuisnummer = 'Vul een huisnummer in';
    }
    if (!formData.eventPostcode) {
      errors.eventPostcode = 'Vul een postcode in';
    }
    if (!formData.eventGemeente) {
      errors.eventGemeente = 'Vul een gemeente in';
    }
    if (!formData.eventEmail) {
      errors.eventEmail = 'Vul een email adres in';
    }
    if (!formData.eventPhoneNumber) {
      errors.eventPhoneNumber = 'Vul een telefoonnummer in';
    }

    // Factuur address validation (only if not same as event)
    if (!formData.sameAsEvent) {
      console.log('Validating factuur fields (sameAsEvent is false)');
      if (!formData.factuurStraat) {
        errors.factuurStraat = 'Vul een straat in';
        console.log('factuurStraat validation failed');
      }
      if (!formData.factuurHuisnummer) {
        errors.factuurHuisnummer = 'Vul een huisnummer in';
        console.log('factuurHuisnummer validation failed');
      }
      if (!formData.factuurPostcode) {
        errors.factuurPostcode = 'Vul een postcode in';
        console.log('factuurPostcode validation failed');
      }
      if (!formData.factuurGemeente) {
        errors.factuurGemeente = 'Vul een gemeente in';
        console.log('factuurGemeente validation failed');
      }
    }

    // Burger selection validation
    if (formData.selectedBurgers.length === 0) {
      errors.selectedBurgers = 'Selecteer minstens één burger';
    }

    setValidationErrors(errors);
    const isValid = Object.keys(errors).length === 0;
    console.log('Validation result:', { isValid, errors });
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitError(null);
    
    if (validateForm()) {
      setShowSummary(true);
    } else {
      // Scroll to the first error
      const firstErrorField = Object.keys(validationErrors)[0];
      const element = document.querySelector(`[name="${firstErrorField}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    setSubmitError(null);
    
    try {
      const response = await submitOfferte(formData);
      
      if (response.success) {
        // Clear form data from localStorage on successful submission
        localStorage.removeItem(STORAGE_KEY);
        alert(response.message);
        setShowSummary(false);
        setFormData(INITIAL_FORM_DATA);
        setValidationErrors({});
      } else {
        setSubmitError(response.message);
      }
    } catch (error) {
      setSubmitError('Er is een fout opgetreden. Probeer het later opnieuw.');
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBurgerSelection = (burgerId: string) => {
    setFormData((prev) => {
      const currentSelected = prev.selectedBurgers;
      if (currentSelected.includes(burgerId)) {
        return {
          ...prev,
          selectedBurgers: currentSelected.filter((id) => id !== burgerId),
        };
      } else if (currentSelected.length < 4) { // Updated from 3 to 4
        return {
          ...prev,
          selectedBurgers: [...currentSelected, burgerId],
        };
      }
      return prev;
    });

    // Clear validation error when burgers are selected
    if (validationErrors.selectedBurgers) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.selectedBurgers;
        return newErrors;
      });
    }
  };

  const handleSameAsEventChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setFormData((prev) => ({
      ...prev,
      sameAsEvent: isChecked,
      factuurStraat: isChecked ? prev.eventStraat : "",
      factuurHuisnummer: isChecked ? prev.eventHuisnummer : "",
      factuurPostcode: isChecked ? prev.eventPostcode : "",
      factuurGemeente: isChecked ? prev.eventGemeente : "",
    }));

    // Clear validation errors for factuur fields when switching to same as event
    if (isChecked) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.factuurStraat;
        delete newErrors.factuurHuisnummer;
        delete newErrors.factuurPostcode;
        delete newErrors.factuurGemeente;
        return newErrors;
      });
    }
  };

  const handleEventAddressChange = (field: keyof FormData, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
      ...(prev.sameAsEvent && {
        [`factuur${field.slice(5)}`]: value,
      }),
    }));
    
    // Clear validation error for the field when it's changed
    if (validationErrors[field]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const updateFormData = (field: keyof FormData, value: string | number | boolean | string[] | {
    chocoladeMousse: number;
    tiramisuClassic: number;
    tiramisuSpeculoos: number;
    chocoladeBrownie: number;
    lemonCheeseCake: number;
  }) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    
    // Clear validation error for the field when it's changed
    if (validationErrors[field]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const updateDessertCount = (dessertId: string, count: number) => {
    setFormData(prev => ({
      ...prev,
      desserts: {
        ...prev.desserts,
        [dessertId]: count
      }
    }));
  };

  return {
    formData,
    showSummary,
    isSubmitting,
    submitError,
    validationErrors,
    handleSubmit,
    handleConfirm,
    handleBurgerSelection,
    handleSameAsEventChange,
    handleEventAddressChange,
    updateFormData,
    updateDessertCount,
    setShowSummary
  };
};
